import styled from '@emotion/styled'
import { theme } from 'styles'

export const TitleStyled = styled.span`
  font-family: ${theme.fontFamily.primary};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  color: ${({ whiteTitle }) =>
    whiteTitle ? theme.colors.lightblue : theme.colors.primarygreen};
  position: relative;
  margin-bottom: 0px;
  display: block;

  &.Title__displayNone {
    display: none;
  }

  * {
    margin: 0;
  }

  ${({ type }) => {
    switch (type) {
      case 'h1':
        return `
        font-size: ${theme.fonts.xxl_small};
        `
      case 'h1-secondary':
        return `
            font-size: ${theme.fonts.xs};
          `
      case 'h1-secondary-large':
        return `
              font-size: ${theme.fonts.xl_small};
            `

      case 'h2':
        return `
          font-size: ${theme.fonts.xs};
          `
      case 'h3':
        return `
            font-size: ${theme.fonts.xl_medium};
            line-height: 1.1;
            font-weight: 500;
          `
      case 'h4':
        return `
            font-size: ${theme.fonts.xs};
          `
      case 'h5':
        return `
        font-size: ${theme.fonts.s};
        `
      case 'h5-secondary':
        return `
          font-size: ${theme.fonts.s};
          `
      default:
        //
        // Default Font Style
        //
        return `
            font-size: ${theme.fonts.xl_medium};
        `
    }
  }};

  @media (min-width: ${theme.breakpoints.md}) {
    ${({ type }) => {
      switch (type) {
        case 'h1':
          return `
            font-size: ${theme.fonts.xxxl_large};
          `
        case 'h1-secondary':
          return `
            font-size: ${theme.fonts.xxs};
          `
        case 'h1-secondary-large':
          return `
              font-size: ${theme.fonts.xxxl_small};
            `

        case 'h2':
          return `
            font-size: ${theme.fonts.xl}; 
          `
        case 'h3':
          return `
            font-size: ${theme.fonts.xxl_large}; 
          `
        case 'h4':
          return `
            font-size: ${theme.fonts.xl_small}; 
          `
        case 'h5':
          return `
            font-size: ${theme.fonts.l};
            `
        case 'h5-secondary':
          return `
                font-size: ${theme.fonts.l_secondary};
                `
        default:
          //
          // Default Font Style
          //
          return `
            font-size: ${theme.fonts.xxl_large}; 
          `
      }
    }};
  }
`
