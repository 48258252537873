import React from 'react'

export const Facebook = ({ className, href }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="31"
        viewBox="0 0 32 31"
        className={className ? className : ''}
      >
        <a href={href} target="_blank" rel="noopener noreferrer">
          <g fill="none" fillOpacity=".39" fillRule="evenodd">
            <g fill="#E2EDED">
              <g>
                <path
                  d="M185.3 10.256l-1.522.001c-1.194 0-1.426.54-1.426 1.332v1.748h2.848l-.371 2.739h-2.477V23.1h-2.97v-7.024H176.9v-2.739h2.483V11.32c0-2.344 1.503-3.619 3.698-3.619 1.051 0 1.955.074 2.219.108v2.448zM180.75 0C172.052 0 165 6.894 165 15.4c0 8.505 7.052 15.4 15.75 15.4 8.699 0 15.75-6.895 15.75-15.4 0-8.506-7.051-15.4-15.75-15.4z"
                  transform="translate(-983 -612) translate(818 612)"
                />
              </g>
            </g>
          </g>
        </a>
      </svg>
    </>
  )
}
