import styled from '@emotion/styled'
import { theme } from 'styles'

export const LogoContainer = styled.div`
  .Navigation__Logo {
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 113px;
    height: auto;

    @media (min-width: ${theme.breakpoints.m}) {
      left: 25px;
      bottom: 19px;
      width: 223px;
      height: 133px;
    }
  }
`
