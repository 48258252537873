import React from 'react'
import PropTypes from 'prop-types'
import {
  FlyoutNavigationContainer,
  FlyoutMainContentStyled,
  PSBIconContainer,
} from './styles.css'
import FlyoutContent from 'components/FlyoutContent'
import Icon from 'components/Icon'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { Desktop } from 'components/Utilities/Media'

const FlyoutNavigation = ({ globalContext, navigationQuery, location }) => {
  const animationProps = {
    openMenu: globalContext?.openMenu,
    animationLoaded: globalContext?.animationLoaded,
    handleOpen: globalContext?.handleOpen,
  }

  return (
    <FlyoutNavigationContainer {...animationProps}>
      <FlyoutMainContentStyled {...animationProps}>
        <FlyoutContent
          navigationQuery={navigationQuery}
          location={location}
          {...animationProps}
        />
        <Desktop>
          <LanguageSwitcher
            location={location}
            clickHandler={globalContext?.handleOpen}
          />
        </Desktop>
        <PSBIconContainer>
          <Icon type="psb-symbol" className="Icon__PSBSymbol" />
        </PSBIconContainer>
      </FlyoutMainContentStyled>
    </FlyoutNavigationContainer>
  )
}

FlyoutNavigation.propTypes = {
  globalContext: PropTypes.object.isRequired,
  navigationQuery: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default FlyoutNavigation
