import { css } from '@emotion/core'

const theme = {
  colors: {
    primarygreen: '#043022',
    green70: 'rgba (4, 48, 34, .7)',
    green40: 'rgba (4, 48, 34, .4)',
    lightblue: '#e7f2f2',
    terracota: '#ad4700',
    orange: '#ff7300',
    yellow: '#ffc71f',
    white: '#ffffff',
    dirtywhite: 'rgba(222, 233, 233, 0.6)',
    success: '',
    error: '',
  },
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    md: '769px',
    m: '960px',
    l: '1100px',
    xl: '1201px',
    xxl: '1440px',
  },
  fontFamily: {
    primary: 'EuclidSquare',
  },
  fonts: {
    xxxs_small: '12px',
    xxxs_medium: '14px',
    xxxs_large: '16px',
    xxxs_extralarge: '17px',
    xxs: '18px',
    xxs_secondary: '19px',
    xs: '20px',
    s: '21px',
    m: '22px',
    l: '24px',
    l_secondary: '25px',
    xl: '27px',
    xl_small: '30px',
    xl_medium: '34px',
    xl_large: '38px',
    xxl_small: '40px',
    xxl_medium: '46px',
    xxl_large: '48px',
    xxxl_small: '75px',
    xxxl_medium: '98px',
    xxxl_large: '127px',
  },
  lineHeight: {
    xxxl_large: '64px',
    xxxl_medium: '60px',
    xxxl_small: '58px',
    xxl: '44px',
    xl: '40px',
    l: '38px',
    m: '30px',
    s: '28px',
    xs: '26px',
    xxs: '24px',
  },
  headerHeight: {
    desktop: '152px',
    mobile: '77px',
  },
  animation: {
    primary: '0.7s ease-in-out forwards',
    secondary: '0.3s ease-in-out forwards',
    tertiary: '1s ease-in-out forwards',
  },
  transition: {
    primary: '300ms',
  },
  letterSpacing: {
    primary: '0.43px;',
  },
  scale: (value, by = 0.1) => {
    by = 1 - by

    if (value.indexOf(' ') >= 0) {
      value = value.split(' ')
      value = value
        .map(v => `${Math.floor(+v.split('px')[0] * by)}px`)
        .join(' ')
      return value
    } else {
      value = value.split('px')[0]
      value = value * by
      return `${Math.floor(value)}px`
    }
  },
}

export default theme

export const h1 = css`
  font-family: ${theme.fontFamily.primary};
  font-size: ${theme.fonts.xxl_small};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.xxxl_large};
  }
`

export const h2 = css`
  font-family: ${theme.fontFamily.primary};
  font-size: ${theme.fonts.xs};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.xl};
  }
`

export const h3 = css`
  font-family: ${theme.fontFamily.primary};
  font-size: ${theme.fonts.xl_medium};
  line-height: 1.1;

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.xxl_large};
  }
`

export const h4 = css`
  font-family: ${theme.fontFamily.primary};
  font-size: ${theme.fonts.xs};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.xl_small};
  }
`

export const h5 = css`
  font-family: ${theme.fontFamily.primary};
  font-size: ${theme.fonts.s};

  @media (min-width: ${theme.breakpoints.md}) {
    font-size: ${theme.fonts.l};
  }
`

export const genericStyle = css`
  h1 {
    ${h1};
    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 48px;
    }
  }

  em {
    font-style: italic;
  }

  h2 {
    ${h2};
  }

  h3 {
    ${h3};
  }

  h4 {
    ${h4};
  }

  h5 {
    ${h5};
  }

  h6 {
    ${h5};
  }

  ul {
  }

  li {
    font-weight: 300;
  }

  p {
    font-weight: 300;
  }

  a {
    color: ${theme.colors.primarygreen};
  }
`
