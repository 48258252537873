import styled from '@emotion/styled'

export const LanguageSwitcherContainer = styled.div`
  margin-top: 50px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 0px;
    position: absolute;
    top: 150px;
    transform: rotate(90deg);
    right: 15px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    right: 95px;
  }

  .Header__LanguageSwitcher-button {
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fonts.xxs_secondary};
    font-weight: 100;

    > * {
      pointer-events: none;
    }
  }
`
