import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      transform: translateY(120%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  `} 1.5s ease;
`

export const TranslateRevealStyled = styled.div`
  span.content__paragraph {
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 0px;
  }

  span.content__paragraph span.word {
    color: transparent;
    overflow: hidden;
    position: relative;
    padding-right: 6px;
    padding-bottom: 3px;
  }

  span.content__paragraph span.word:before {
    display: block;
    content: attr(data-word);
    position: absolute;
    top: 0;
    left: 0;
    padding: inherit;
    letter-spacing: 0px;
  }

  span.content__paragraph.animate span.word:before {
    animation: ${reveal1};
  }

  span.content__paragraph span.word:before {
    animation: ${props => (props.isVisible ? reveal1 : '')};
  }
`
