module.exports = {
  'en-gb': {
    default: false,
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_gb',
    defaultTitle: 'Constructions psb Inc.',
    defaultDescription:
      'General contractor - entrepreneur général en construction',
    headline: 'General contractor - entrepreneur général en construction',
    category: 'Category',
    categories: 'Categories',
  },
  'fr-ca': {
    default: true,
    path: 'fr',
    locale: 'fr-ca',
    siteLanguage: 'fr',
    ogLang: 'fr_CA',
    defaultTitle: 'Constructions psb Inc.',
    defaultDescription:
      'General contractor - entrepreneur général en construction',
    headline: 'General contractor - entrepreneur général en construction',
    category: 'Categorie',
    categories: 'Categories',
  },
}
