import React, { createContext, useState, useEffect } from 'react'

const GlobalContext = createContext()

export const GlobalProvider = ({ children, location }) => {
  const [openMenu, setMenuOpen] = useState(false)
  const [animationLoaded, setAnimationLoaded] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [lightColorTheme, setLightColorTheme] = useState(true)

  const handleOpen = () => {
    setMenuOpen(!openMenu)
    setTimeout(() => {
      setAnimationLoaded(!animationLoaded)
    }, 700)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        window.Splitting({ by: 'lines' })
      }, 1000)
    }
  }, [location.pathname])

  return (
    <>
      <GlobalContext.Provider
        value={{
          openMenu,
          setMenuOpen,
          handleOpen,
          animationLoaded,
          setSelectedCategory,
          selectedCategory,
          setLightColorTheme,
          lightColorTheme,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </>
  )
}

export default GlobalContext
