import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LineContainer, LineContent } from './styles.css'
import IntersectionVisible from 'react-intersection-visible'

const AnimatedLine = ({
  children,
  className,
  isNavigation,
  heightOffsetSecondary,
  shortLine,
  noPrimaryTitle,
  heightOffset,
  openMenu,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const lineContentProps = {
    shortLine: shortLine ? shortLine : null,
    className: className ? className : '',
    openMenu: openMenu ? openMenu : null,
  }

  const lineContainerProps = {
    shortLine: shortLine ? shortLine : null,
    heightOffset: heightOffset ? heightOffset : null,
    heightOffsetSecondary: heightOffsetSecondary ? heightOffsetSecondary : null,
    className: className ? className : '',
    noPrimaryTitle: noPrimaryTitle ? noPrimaryTitle : null,
    isNavigation: isNavigation ? isNavigation : null,
  }

  const observerProps = {
    onShow: () => setIsVisible(true),
    onHide: () => setIsVisible(false),
  }

  return (
    <IntersectionVisible {...observerProps}>
      <LineContainer {...lineContainerProps}>
        <LineContent {...lineContentProps} isVisible={isVisible}>
          {children}
        </LineContent>
      </LineContainer>
    </IntersectionVisible>
  )
}

AnimatedLine.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isNavigation: PropTypes.bool,
  heightOffsetSecondary: PropTypes.number,
  shortLine: PropTypes.bool,
  noPrimaryTitle: PropTypes.bool,
  heightOffset: PropTypes.number,
  openMenu: PropTypes.bool,
}

export default AnimatedLine
