import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TranslateRevealStyled } from './styles'
import IntersectionVisible from 'react-intersection-visible'

const TranslateReveal = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <TranslateRevealStyled isVisible={isVisible}>
      <IntersectionVisible
        onShow={() => setIsVisible(true)}
        onHide={() => setIsVisible(false)}
      >
        {children}
      </IntersectionVisible>
    </TranslateRevealStyled>
  )
}

TranslateReveal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TranslateReveal
