import React from 'react'

export const LinkedIn = ({ className, href }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        className={className ? className : ''}
      >
        <a href={href} target="_blank" rel="noopener noreferrer">
          <g fill="none" fillOpacity=".39" fillRule="evenodd">
            <g fill="#E2EDED">
              <g>
                <path
                  d="M23.1 23.1h-3.142v-4.983c0-1.188-.021-2.716-1.634-2.716-1.635 0-1.884 1.295-1.884 2.632V23.1H13.3V12.855h3.013v1.4h.043c.42-.805 1.445-1.655 2.974-1.655 3.182 0 3.77 2.122 3.77 4.88v5.62zM9.451 11.2c-.97 0-1.751-.784-1.751-1.75S8.482 7.7 9.451 7.7c.965 0 1.749.784 1.749 1.75s-.784 1.75-1.749 1.75zM11.2 23.1H7.7V12.6h3.5v10.5zM15.4 0C6.894 0 0 6.894 0 15.4c0 8.505 6.894 15.4 15.4 15.4s15.4-6.895 15.4-15.4C30.8 6.894 23.906 0 15.4 0z"
                  transform="translate(-46 -666) translate(46 666)"
                />
              </g>
            </g>
          </g>
        </a>
      </svg>
    </>
  )
}
