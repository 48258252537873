import React from 'react'
import PropTypes from 'prop-types'
import {
  FlyoutContentStyled,
  MenuColumn,
  MenuItemHeading,
  MenuItemBody,
} from './styles.css'
import SocialMedia from 'components/SocialMedia'
import LanguageSwitcher from 'components/LanguageSwitcher'
import LinkFormatter from 'components/LinkFormatter'
import { LargeDesktopDown } from 'components/Utilities/Media'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const FlyoutContent = ({ navigationQuery, location, openMenu, handleOpen }) => {
  const socialMediaQuery = navigationQuery?.body?.find(
    data => data.__typename === 'PrismicNavigationBodySocialMedia'
  )
  const theme = useTheme()
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('960'))

  return (
    <>
      <FlyoutContentStyled openMenu={openMenu}>
        {navigationQuery?.body?.map((item, index) => {
          if (
            item.__typename !== 'PrismicNavigationBodyMainMenu' &&
            isLargeDesktop
          ) {
            return (
              <MenuColumn
                key={`${item.__typename}--${index}`}
                className={
                  item.__typename === 'PrismicNavigationBodySocialMedia'
                    ? 'MenuColumn__SocialMedia'
                    : ''
                }
              >
                {index === 2 ? (
                  // <LargeDesktopDown>
                  //   <LanguageSwitcher
                  //     location={location}
                  //     style={{ marginTop: '50px' }}
                  //     clickHandler={handleOpen}
                  //   />
                  // </LargeDesktopDown>
                  <></>
                ) : (
                  <></>
                )}
                {item.primary?.main_title?.text ? (
                  <MenuItemHeading whiteText as="h4">
                    {item.primary?.main_title?.text}
                  </MenuItemHeading>
                ) : (
                  <></>
                )}
                {item.items?.map((element, index) => {
                  if (item.__typename !== 'PrismicNavigationBodySocialMedia') {
                    return element.menu_item_link?.uid &&
                      element.menu_item_label?.text ? (
                      <LinkFormatter
                        pageHandle={`/${element.menu_item_link?.uid}`}
                        key={index}
                        onClickHandler={handleOpen}
                        pageType={element?.page_type}
                      >
                        <MenuItemBody whiteTitle type="h5-secondary" as="h5">
                          {element.menu_item_label?.text}
                        </MenuItemBody>
                      </LinkFormatter>
                    ) : (
                      <></>
                    )
                  } else if (
                    item.__typename === 'PrismicNavigationBodySocialMedia' &&
                    index === 0
                  ) {
                    return (
                      <>
                        <SocialMedia data={socialMediaQuery} key={index} />
                      </>
                    )
                  } else if (
                    item.__typename === 'PrismicNavigationBodyMainMenu'
                  ) {
                    return (
                      <>
                        <LargeDesktopDown>
                          {element.menu_item_link?.uid &&
                          element.menu_item_label?.text ? (
                            <LinkFormatter
                              pageHandle={`/${element.menu_item_link?.uid}`}
                              key={index}
                              onClickHandler={handleOpen}
                              pageType={element?.page_type}
                            >
                              <MenuItemBody
                                whiteTitle
                                type="h5-secondary"
                                as="h5"
                              >
                                {element.menu_item_label?.text}
                              </MenuItemBody>
                            </LinkFormatter>
                          ) : (
                            <></>
                          )}
                        </LargeDesktopDown>
                      </>
                    )
                  }
                })}
              </MenuColumn>
            )
          } else if (!isLargeDesktop) {
            return (
              <MenuColumn
                key={`${item.__typename}--${index}`}
                className={
                  item.__typename === 'PrismicNavigationBodySocialMedia'
                    ? 'MenuColumn__SocialMedia'
                    : ''
                }
              >
                {item.primary?.main_title?.text ? (
                  <MenuItemHeading whiteText as="h4">
                    {item.primary?.main_title?.text}
                  </MenuItemHeading>
                ) : (
                  <></>
                )}
                {item.items?.map((element, index) => {
                  if (item.__typename !== 'PrismicNavigationBodySocialMedia') {
                    return element.menu_item_link?.uid &&
                      element.menu_item_label?.text ? (
                      <LinkFormatter
                        pageHandle={`/${element.menu_item_link?.uid}`}
                        key={index}
                        onClickHandler={handleOpen}
                        pageType={element?.page_type}
                      >
                        <MenuItemBody whiteTitle type="h5-secondary" as="h5">
                          {element.menu_item_label?.text}
                        </MenuItemBody>
                      </LinkFormatter>
                    ) : (
                      <></>
                    )
                  } else if (
                    item.__typename === 'PrismicNavigationBodySocialMedia' &&
                    index === 0
                  ) {
                    return (
                      <>
                        <SocialMedia data={socialMediaQuery} key={index} />
                      </>
                    )
                  }
                })}
              </MenuColumn>
            )
          }
        })}
        <LargeDesktopDown>
          <LanguageSwitcher location={location} clickHandler={handleOpen} />
        </LargeDesktopDown>
      </FlyoutContentStyled>
    </>
  )
}

FlyoutContent.propTypes = {
  navigationQuery: PropTypes.object.isRequired,
  openMenu: PropTypes.bool.isRequired,
  location: PropTypes.object,
  handleOpen: PropTypes.func,
}

export default FlyoutContent
