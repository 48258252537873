import React from 'react'

export const PSBSymbol = ({ className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="78"
        height="78"
        viewBox="0 0 78 78"
        className={className ? className : ""}
      >
        <g fill="none" fillOpacity=".7" fillRule="evenodd">
          <g fill="#E2EDED">
            <path
              d="M48.945 0v24.473h-19.89V0H0v29.055h24.473v19.89H0V78h29.055V53.527h19.89V78H78V48.945H53.527v-19.89H78V0H48.945zm4.582 24.473h19.89V4.582h-19.89v19.89zm-48.945 0h19.891V4.582H4.583v19.89zm24.473 24.472h19.89v-19.89h-19.89v19.89zm24.472 24.473h19.89V53.527h-19.89v19.89zm-48.945 0h19.891V53.527H4.583v19.89z"
              transform="translate(-1516 -729) translate(1516 729)"
            />
          </g>
        </g>
      </svg>
    </>
  )
}
