import React from 'react'

export const AfterPay = () => {
  return (
    <svg
      className="AfterPayLogo"
      width="140px"
      height="29px"
      viewBox="0 0 140 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols-+-navigation"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Full.H.logout_wishlist"
          transform="translate(-71.000000, -22.000000)"
        >
          <g id="Top-Nav---logged-out">
            <g id="afterpay_logo" transform="translate(71.000000, 22.000000)">
              <path
                d="M68.6152083,14.6117708 C68.6152083,12.6935417 67.0458854,11.0949167 65.1856562,11.0949167 C63.325125,11.0949167 61.7555,12.6935417 61.7555,14.6117708 C61.7555,16.5013021 63.325125,18.1289271 65.1856562,18.1289271 C67.0458854,18.1289271 68.6152083,16.5013021 68.6152083,14.6117708 Z M70.0978333,9.69959375 C71.4058542,11.0366146 72.1323646,12.7805417 72.1323646,14.6117708 C72.1323646,16.4433021 71.4058542,18.1872292 70.0978333,19.52425 C68.7898125,20.8319688 67.0458854,21.5297813 65.1856562,21.5297813 C63.9646354,21.5297813 62.8599167,21.093875 61.9008021,20.2217604 L61.7555,20.1057604 L61.7555,27.8901458 L58.2386458,29 L58.2386458,7.95536458 L61.7555,7.95536458 L61.7555,9.00208333 L61.9008021,8.85647917 C62.9475208,7.83936458 64.1389375,7.6940625 65.1856562,7.6940625 C67.0168854,7.6940625 68.7608125,8.391875 70.0978333,9.69959375 Z"
                id="Fill-1"
                fill="#31322F"
              ></path>
              <path
                d="M76.8998438,14.6117708 C76.8998438,16.5303021 78.4694688,18.1289271 80.33,18.1289271 C82.1902292,18.1289271 83.7595521,16.5303021 83.7595521,14.6117708 C83.7595521,12.7225417 82.1902292,11.0949167 80.33,11.0949167 C78.4694688,11.0949167 76.8998438,12.7225417 76.8998438,14.6117708 Z M75.4172188,19.52425 C74.1091979,18.1872292 73.3826875,16.4433021 73.3826875,14.6117708 C73.3826875,12.7805417 74.1091979,11.0366146 75.4172188,9.69959375 C76.7252396,8.391875 78.4694688,7.6940625 80.33,7.6940625 C81.5504167,7.6940625 82.6551354,8.12996875 83.61425,9.00208333 L83.7595521,9.11808333 L83.7595521,7.95536458 L87.2767083,7.95536458 L87.2767083,21.2684792 L83.7595521,21.2684792 L83.7595521,20.2217604 L83.61425,20.3673646 C82.5681354,21.3844792 81.3761146,21.5297812 80.33,21.5297812 C78.4984688,21.5297812 76.7548438,20.8319687 75.4172188,19.52425 Z"
                id="Fill-4"
                fill="#31322F"
              ></path>
              <polyline
                id="Fill-7"
                fill="#31322F"
                points="90.7944688 29 93.9630208 21.2974792 88.6436354 7.95536458 92.5383958 7.95536458 95.85225 16.2396979 99.2238021 7.95536458 103.089865 7.95536458 94.6022292 29 90.7944688 29"
              ></polyline>
              <path
                d="M6.80442708,9.93521875 C4.24185417,9.93521875 2.156875,12.0331875 2.156875,14.6117708 C2.156875,17.1903542 4.24185417,19.2883229 6.80442708,19.2883229 C9.36730208,19.2883229 11.4522812,17.1903542 11.4522812,14.6117708 C11.4522812,12.0331875 9.36730208,9.93521875 6.80442708,9.93521875 Z M6.80442708,21.3875 C4.98497917,21.3875 3.27246875,20.6927083 1.982875,19.4309062 C0.7050625,18.1243958 0,16.4118854 0,14.6117708 C0,12.8116563 0.7050625,11.0991458 1.98559375,9.79021875 C3.27277083,8.53113542 4.98497917,7.83634375 6.80442708,7.83634375 C8.64502083,7.83634375 10.1950104,8.81026042 11.1707396,9.62709375 L11.4522813,9.86271875 L11.4522813,8.12664583 L13.6094583,8.12664583 L13.6094583,21.0971979 L11.4522813,21.0971979 L11.4522813,19.3608229 L11.1707396,19.5961458 C10.1950104,20.4132812 8.64502083,21.3875 6.80442708,21.3875 Z"
                id="Fill-10"
                fill="#31322F"
              ></path>
              <path
                d="M17.5144896,21.0971979 L17.5144896,10.1678229 L15.5379583,10.1678229 L15.5379583,8.12664583 L17.5144896,8.12664583 L17.5144896,4.29320833 C17.5144896,1.94420833 19.3795521,0.10421875 21.760875,0.10421875 L24.4735833,0.10421875 L23.9262083,2.14539583 L21.8191771,2.14539583 C20.6751875,2.14539583 19.6713646,3.17610417 19.6713646,4.35151042 L19.6713646,8.12664583 L23.7697292,8.12664583 L23.7697292,10.1678229 L19.6713646,10.1678229 L19.6713646,21.0971979 L17.5144896,21.0971979"
                id="Fill-11"
                fill="#31322F"
              ></path>
              <path
                d="M31.9045312,21.0971979 C29.5627812,21.0971979 27.6581458,19.1916562 27.6581458,16.8502083 L27.6581458,10.1678229 L25.6813125,10.1678229 L25.6813125,8.12664583 L27.6581458,8.12664583 L27.6581458,0.10421875 L29.8147187,0.10421875 L29.8147187,8.12664583 L33.9133854,8.12664583 L33.9133854,10.1678229 L29.8147187,10.1678229 L29.8147187,16.7922083 C29.8147187,17.9769792 30.8387812,19.0560208 31.9631354,19.0560208 L34.0695625,19.0560208 L34.6172396,21.0971979 L31.9045312,21.0971979"
                id="Fill-12"
                fill="#31322F"
              ></path>
              <path
                d="M41.1935938,10.0222188 C39.5260938,10.0222188 37.9779167,11.2245104 37.3405208,13.0140521 L37.3196771,13.0530208 L37.1964271,13.3007292 L45.1925729,13.3007292 L45.0379063,12.9944167 C44.4403854,11.2257188 42.8916042,10.0222188 41.1935938,10.0222188 Z M41.1899688,21.3295 C39.5019271,21.3282917 37.9489167,20.62625 36.8164063,19.3523646 C35.7772396,18.2268021 35.1694479,16.8154688 35.0567708,15.2681979 C35.028375,15.0645938 35.028375,14.8730729 35.028375,14.6700729 C35.028375,14.1619688 35.0857708,13.6463125 35.19875,13.1376042 C35.4485729,11.9715625 36.0074271,10.8831563 36.8155,9.98898958 C37.9413646,8.75044792 39.5372708,8.03964583 41.1935938,8.03964583 C42.8644167,8.03964583 44.4304167,8.732625 45.6028021,9.99080208 C46.4441042,10.922125 47.0029583,12.0090208 47.2168333,13.1324688 C47.386,14.1254167 47.3902292,14.7419688 47.3721042,15.051 L37.09825,15.051 L37.09825,15.2805833 C37.399125,17.577625 39.1327813,19.3161146 41.132875,19.3469271 C42.3623542,19.2868125 43.5752188,18.7838438 44.4156146,17.9945 L46.215125,19.0690104 C45.7266563,19.5864792 45.20375,20.0121146 44.6216354,20.3664583 C43.6727917,20.9355833 42.4859063,21.2687812 41.1899688,21.3295 Z"
                id="Fill-13"
                fill="#31322F"
              ></path>
              <path
                d="M49.4903125,21.0971979 L49.4903125,8.12664583 L51.6477917,8.12664583 L51.6477917,9.76605208 L51.9459479,9.43678125 C52.7108229,8.59276042 54.9592292,7.90491667 56.4539375,7.84117708 L55.9277083,9.99533333 C53.5554479,10.0633021 51.6477917,11.9126562 51.6477917,14.1758646 L51.6477917,21.0971979 L49.4903125,21.0971979"
                id="Fill-14"
                fill="#31322F"
              ></path>
              <path
                d="M121.714208,11.6323229 C122.996552,10.8901042 124.202167,10.1919896 125.491458,9.44584375 C125.073979,8.71329167 125.175781,8.88940625 124.795458,8.19823958 C124.392479,7.46598958 124.544427,7.17810417 125.376667,7.1720625 C127.799979,7.15514583 130.223896,7.15303125 132.647208,7.16571875 C133.367073,7.16964583 133.540771,7.47777083 133.179781,8.11275 C131.981115,10.2206875 130.769156,12.3210729 129.546323,14.4151146 C129.155729,15.083625 128.814073,15.0821146 128.405958,14.4281042 C127.986667,13.7559687 128.098438,13.9221146 127.649844,13.1602604 C126.385625,13.8943229 125.122313,14.6280833 123.884677,15.3467396 C123.95899,15.5406771 124.053844,15.6651354 124.131479,15.8001667 C125.06401,17.4232604 125.480583,18.1984062 126.444229,19.8030729 C127.586104,21.7046875 129.979813,21.8191771 131.339188,20.0616562 C131.490229,19.8662083 131.628583,19.6586771 131.752135,19.4448021 C134.039208,15.4832812 136.32749,11.5226667 138.600969,7.55329167 C138.831458,7.15152083 139.039292,6.71259375 139.139281,6.26430208 C139.537729,4.47747917 138.212188,2.78822917 136.34924,2.77826042 C131.44824,2.75228125 126.546333,2.74835417 121.645333,2.79155208 C119.412938,2.8111875 118.071385,5.04630208 119.051948,7.01075 C119.371552,7.65026042 119.754896,8.25805208 120.114073,8.877625 C120.789833,10.0439687 120.968365,10.3508854 121.714208,11.6323229"
                id="Fill-16"
                fill="#306E9A"
              ></path>
              <path
                d="M110.816854,23.8712292 C110.818969,22.4009896 110.822896,19.5230417 110.822896,19.5230417 C110.822896,19.5230417 110.22024,19.5166979 109.405823,19.5166979 C108.576604,19.5166979 108.405625,19.2236771 108.814948,18.5068333 C110.007573,16.4191354 111.21349,14.3386875 112.431792,12.2657917 C112.793688,11.6501458 113.114198,11.5960729 113.509625,12.2805937 C114.712521,14.3640625 115.919344,16.4454167 117.106531,18.5379479 C117.485646,19.2058542 117.314063,19.4982708 116.54949,19.5227396 C115.760146,19.5227396 115.085292,19.5218333 115.085292,19.5218333 L115.08499,23.8830104 C115.08499,23.8830104 118.302781,23.8836146 120.183854,23.8836146 C122.383927,23.8509896 123.674729,21.8545208 122.844,19.8127396 C122.751865,19.585875 122.642813,19.3638437 122.520771,19.1511771 C120.26149,15.2153333 118.003115,11.2788854 115.730542,7.35059375 C115.500354,6.95305208 115.227573,6.5558125 114.892865,6.24678125 C113.55826,5.01458333 111.44851,5.31032292 110.512052,6.90351042 C108.047958,11.0955208 105.602594,15.2993125 103.197708,19.5254583 C102.102354,21.4506354 103.351469,23.7153542 105.525563,23.8525 C106.233344,23.8972083 109.255688,23.8712292 110.816854,23.8712292"
                id="Fill-18"
                fill="#1F4066"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
