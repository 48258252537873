import React from 'react'

export const ScrollLineElement = ({ className }) => {
  return (
    <>
      <svg
        width="3"
        height="38"
        viewBox="0 0 3 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className= {className ? className : ""}
      >
        <line
          x1="1.5"
          y1="37.5"
          x2="1.5"
          y2="0.5"
          stroke="#FFC71F"
          strokeWidth="2"
        />
      </svg>
    </>
  )
}
