import { css } from '@emotion/core'
import theme from './theme'
import reset from './reset'

const globalStyle = css`
  ${reset}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    overflow-x: hidden;
  }
  body {
    color: ${theme.colors.primaryblue};
    background-color: ${theme.colors.bg};
    height: 100%;

    ::selection {
      color: 'white';
      background-color: ${theme.colors.primaryblue};
    }
  }

  .scroll-to-top {
    svg {
      width: 100%;
      height: auto;
    }
  }

  #hs-eu-cookie-confirmation {
    display: none !important;
  }

  .styles_scroll-to-top__2A70v {
    background: none;
    box-shadow: none;
    right: 40px;
    bottom: 630px;
    position: fixed;
  }

  .mobile_filter_drawer {
    .MuiDrawer-paper {
      border: none;
      max-width: 100%;
      .MuiPaper-root {
        overflow-y: scroll;
      }
    }
  }
`

export default globalStyle
