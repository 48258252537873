import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  `} 1.5s ease forwards;
`

export const LineContainer = styled.div`
  width: ${props => (props.shortLine ? '65%' : '100%')};
  position: absolute;
  bottom: ${props => (props.shortLine ? 'unset' : '0')};
  right: 0;
  top: ${props =>
    props.heightOffset ? `calc(${props.heightOffset}px + 15px)` : 'unset'};

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: ${props => (props.shortLine ? '160%' : '100%')};
    transform: translate(50%, 0px);
    right: ${props => (props.isNavigation ? '50%' : '0')};
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    bottom: ${props =>
      props.noPrimaryTitle
        ? `calc(${props.heightOffsetSecondary}px + 35px)`
        : props.isNavigation
        ? '0'
        : 'unset'};
  }
`

export const LineContent = styled.div`
  background: ${props =>
    !props.openMenu
      ? props.theme.colors.primarygreen
      : props.theme.colors.white};
  height: 1px;
  animation: ${props => (props.isVisible ? reveal1 : 'initial')};
  position: absolute;
  transform: scaleX(0);
  width: 100%;
  transform-origin: right;
  right: 0;
  bottom: 0;
`
