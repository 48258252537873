import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  `} .7s ease-in-out forwards;
`

const reveal2 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  `} 0.7s ease-in-out forwards;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => !props.openMenu && !props.animationLoaded ? 'transparent' : props.theme.colors.primarygreen};
  transform-origin: bottom;
  animation: ${props => (props.openMenu && !props.animationLoaded ? reveal1 : !props.openMenu && props.animationLoaded ? reveal2 : '')};
  z-index: -1;
`
