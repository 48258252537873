import styled from '@emotion/styled'
import Text from 'components/Utilities/Text'

export const ContainerStyled = styled.div`
  width: 100vw;
  overflow: hidden;
  height: 77px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;

  .Text {
    color: ${props =>
      props.lightColorTheme || props.openMenu
        ? props.theme.colors.white
        : props.theme.colors.primarygreen};
  }

  .Navigation__Logo path {
    fill: ${props =>
      props.lightColorTheme || props.openMenu
        ? props.theme.colors.white
        : props.theme.colors.primarygreen};
  }

  .AnimatedLine__Navigation {
    background: ${props =>
      props.lightColorTheme || props.openMenu
        ? props.theme.colors.white
        : props.theme.colors.primarygreen};
  }

  #burger-menu path:first-of-type,
  #burger-menu path:last-of-type {
    stroke: ${props =>
      props.lightColorTheme || props.openMenu
        ? props.theme.colors.white
        : props.theme.colors.primarygreen};
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    position: ${props => (props.openMenu ? 'fixed' : 'relative')};
    height: 152px;
    left: 50%;
    transform: translate(-50%, 0px);
  }
`

export const NavigationMainContainerStyled = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 2000px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    justify-content: center;
  }
`

export const MenuItemsContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 354px;
  margin-top: 25px;

  a {
    text-decoration: none;
    padding-top: 12px;
    cursor: pointer;
  }
`

export const MenuItemsStyled = styled(Text)`
  margin-right: 80px;
  font-weight: bold;
  color: ${props =>
    props.openMenu
      ? props.theme.colors.white
      : props.theme.colors.primarygreen};
  transition: color 0.5s;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-right: 30px;
  }

  &:hover {
    color: ${props =>
      props.openMenu
        ? props.theme.colors.orange
        : props.theme.colors.primarygreen};
  }
`

export const BurgerMenuContainer = styled.div`
  margin-right: 97px;
  width: initial;
  height: initial;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 38px;
    height: 28px;
    margin-right: 22px;
    bottom: 5px;
    position: relative;
  }

  svg {
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 38px;
      height: 28px;
    }
  }
`
