import React from 'react'

export const Twitter = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      className={className ? className : ''}
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g
                fill="#AD4700"
                transform="translate(-341 -1747) translate(185 1746) translate(156 1)"
              >
                <circle cx="18" cy="18" r="18" />
              </g>
              <g fill="#FFF">
                <path
                  d="M6.696 17.235c7.983 0 12.348-6.615 12.348-12.348 0-.189 0-.378-.009-.558.846-.612 1.584-1.377 2.169-2.25-.774.342-1.611.576-2.493.684.9-.54 1.584-1.386 1.908-2.403-.837.495-1.764.855-2.754 1.053C17.073.567 15.948.045 14.697.045c-2.394 0-4.338 1.944-4.338 4.338 0 .342.036.675.117.99C6.867 5.193 3.672 3.465 1.53.837c-.369.639-.585 1.386-.585 2.178 0 1.503.765 2.835 1.935 3.609-.711-.018-1.377-.216-1.962-.54v.054c0 2.106 1.494 3.852 3.483 4.257-.36.099-.747.153-1.143.153-.279 0-.549-.027-.819-.081.549 1.728 2.151 2.979 4.05 3.015-1.485 1.161-3.357 1.854-5.391 1.854-.351 0-.693-.018-1.035-.063 1.899 1.242 4.185 1.962 6.633 1.962"
                  transform="translate(-341 -1747) translate(185 1746) translate(156 1) translate(8.01 10.26)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
