import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import LocaleContext from 'context/LocaleProvider'
import GlobalContext from 'context/GlobalStateProvider'
import { getQuery } from 'functions/getQuery'
import { LargeDesktopUp } from 'components/Utilities/Media'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  NavigationMainContainerStyled,
  MenuItemsContainerStyled,
  ContainerStyled,
  MenuItemsStyled,
  BurgerMenuContainer,
} from './style'
import Logo from 'components/Logo'
import FlyoutNavigation from 'components/FlyoutNavigation'
import AnimatedLine from 'components/AnimatedLine'
import AnimatedBackground from 'components/AnimatedBackground'
import { Link } from 'gatsby'
import LinkFormatter from 'components/LinkFormatter'
import Icon from 'components/Icon'

const Navigation = ({ location }) => {
  const data = useStaticQuery(graphql`
    query MainNavigationQuery {
      navigation: allPrismicNavigation {
        nodes {
          lang
          data {
            body {
              ... on PrismicNavigationBodySubmenu1 {
                id
                primary {
                  main_title {
                    text
                  }
                }
                items {
                  page_type
                  menu_item_link {
                    uid
                  }
                  menu_item_label {
                    text
                  }
                }
              }
              ... on PrismicNavigationBodyMainMenu {
                id
                items {
                  page_type
                  menu_item_link {
                    uid
                  }
                  menu_item_label {
                    text
                  }
                }
                primary {
                  visible
                  main_title {
                    text
                  }
                }
              }
              ... on PrismicNavigationBodySubmenu2 {
                id
                primary {
                  main_title {
                    text
                  }
                }
                items {
                  page_type
                  menu_item_link {
                    uid
                  }
                  menu_item_label {
                    text
                  }
                }
              }
              ... on PrismicNavigationBodySocialMedia {
                id
                primary {
                  main_title {
                    text
                  }
                }
                items {
                  social_media_link {
                    url
                  }
                }
              }
            }
            menu_items {
              page_type
              menu_item_label {
                text
              }
              menu_item_link {
                uid
              }
            }
          }
        }
      }
    }
  `)

  const lang = useContext(LocaleContext)
  const globalContext = useContext(GlobalContext)
  const i18n = lang.i18n[lang.locale]
  const navigation = data.navigation.nodes
    .filter(node => node.lang === i18n.locale)
    .map(r => r.data)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('769'))
  
  return (
    <>
      <ContainerStyled
        openMenu={globalContext?.openMenu}
        animationLoaded={globalContext?.animationLoaded}
        lightColorTheme={globalContext?.lightColorTheme}
      >
        <NavigationMainContainerStyled>
          <Link to={i18n.locale === 'en-gb' ? '/en' : '/'}>
            <Logo
              className="Navigation__Logo"
              openMenu={globalContext?.openMenu}
              clickHandler={
                globalContext?.openMenu ? globalContext?.handleOpen : null
              }
            />
          </Link>
          <LargeDesktopUp>
            <MenuItemsContainerStyled>
              {navigation?.[0]?.menu_items.map((item, index) => {
                return (
                  <LinkFormatter
                    pageHandle={item.menu_item_link?.uid}
                    pageType={item.page_type}
                    key={index}
                    onClickHandler={
                      globalContext?.openMenu ? globalContext?.handleOpen : null
                    }
                  >
                    <MenuItemsStyled
                      type="secondary"
                      openMenu={globalContext?.openMenu}
                    >
                      {item.menu_item_label?.text}
                    </MenuItemsStyled>
                  </LinkFormatter>
                )
              })}
            </MenuItemsContainerStyled>
          </LargeDesktopUp>
          <BurgerMenuContainer
            onClick={globalContext?.handleOpen}
            openMenu={globalContext?.openMenu}
          >
            <Icon
              type="burger-menu"
              openMenu={globalContext?.openMenu}
              isDesktop={isDesktop}
            />
          </BurgerMenuContainer>
        </NavigationMainContainerStyled>
        <AnimatedLine
          className="AnimatedLine__Navigation"
          isNavigation
          openMenu={globalContext?.openMenu}
        />
        <AnimatedBackground
          openMenu={globalContext?.openMenu}
          animationLoaded={globalContext?.animationLoaded}
        />
      </ContainerStyled>
      <FlyoutNavigation
        globalContext={globalContext}
        navigationQuery={navigation[0]}
        location={location}
      />
    </>
  )
}

Navigation.propTypes = {
  locale: PropTypes.string,
}

export default Navigation
