import React from 'react'
import PropTypes from 'prop-types'
import { SocialMediaContainerStyled } from './styles'
import Icon from 'components/Icon'

const SocialMedia = ({ className, data }) => {
  const facebookLink = data?.items?.find(link =>
    link?.social_media_link?.url?.includes('facebook')
  ).social_media_link?.url
  const instagramLink = data?.items?.find(link =>
    link?.social_media_link?.url?.includes('instagram')
  ).social_media_link?.url
  const pinterestLink = data?.items?.find(link =>
    link?.social_media_link?.url?.includes('pinterest')
  ).social_media_link?.url
  const linkedInLink = data?.items?.find(link =>
    link?.social_media_link?.url?.includes('linkedin')
  ).social_media_link?.url
  return (
    <SocialMediaContainerStyled>
      <Icon
        type="linkedin"
        className={
          className ? `${className} "Icon__SocialMedia"` : 'Icon__SocialMedia'
        }
        href={linkedInLink}
      />
      <Icon
        type="instagram"
        className={
          className ? `${className} "Icon__SocialMedia"` : 'Icon__SocialMedia'
        }
        href={instagramLink}
      />
      <Icon
        type="pinterest"
        className={
          className ? `${className} "Icon__SocialMedia"` : 'Icon__SocialMedia'
        }
        href={pinterestLink}
      />
      <Icon
        type="facebook"
        className={
          className ? `${className} "Icon__SocialMedia"` : 'Icon__SocialMedia'
        }
        href={facebookLink}
      />
    </SocialMediaContainerStyled>
  )
}

SocialMedia.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
}

export default SocialMedia
