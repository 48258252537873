/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react'
import { LocaleProvider } from 'context/LocaleProvider'
import { GlobalProvider } from 'context/GlobalStateProvider'
import { StyleProvider } from 'context/StyleProvider'
import globalStyle from './src/utils/styles/global'
import ClientOnly from './src/components/ClientOnly'
import Layout from './src/components/Layout'
import { theme } from './src/utils/styles'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { PreviewStoreProvider } from 'gatsby-source-prismic'
import Navigation from 'components/Navigation'
import SEO from 'components/Utilities/SEO'
import './app.css'
import 'styles/splitting-cells.css'
import 'styles/splitting.css'

const wrapPageElement = ({ element, props }) => {
  let seoProps = null
  const home = props?.data?.allPrismicPage?.edges?.filter(
    ({ node }) => node?.lang === props?.pageContext?.locale
  )
  const homeSeoQuery = home?.[0]?.node?.data?.seo_component?.document?.data
  const articleSeoQuery = props?.data?.prismicArticle?.data
  const blogSeoQuery =
    props?.data?.prismicBlogGlobalContent?.data?.seo?.document?.data
  const contactSeoQuery =
    props?.data?.contact?.data?.seo_component?.document?.data
  const expertiseSeoQuery =
    props?.data?.expertise?.nodes?.[0]?.data?.seo_component?.document?.data
  const genericSeoQuery =
    props?.data?.allPrismicGenericTemplate?.nodes?.[0]?.data?.seo_component
      ?.document?.data
  const netZeroSeoQuery =
    props?.data?.allPrismicNetzeroPage?.nodes?.[0]?.data?.seo_component
      ?.document?.data
  const projectsSeoQuery =
    props?.data?.allPrismicProjectsLanding?.nodes?.[0]?.data?.seo_component
      ?.document?.data
  const pageBuilderSeoQuery =
    props?.data?.prismicPage?.data?.seo_component?.document?.data
  const singleListingSeoQuery = props.data?.listing?.nodes?.[0]?.data

  const seo =
    homeSeoQuery ||
    articleSeoQuery ||
    blogSeoQuery ||
    contactSeoQuery ||
    expertiseSeoQuery ||
    genericSeoQuery ||
    netZeroSeoQuery ||
    pageBuilderSeoQuery ||
    singleListingSeoQuery ||
    projectsSeoQuery

  const breadcrumb_title = seo?.breadcrumb_title?.text
  const open_graph_title = seo?.open_graph_title?.text
  const open_graph_description = seo?.open_graph_description?.text
  const open_graph_image = seo?.open_graph_image?.localFile?.url
  const seo_description = seo?.seo_description?.text
  const seo_title = seo?.seo_title?.text

  if (seo) {
    seoProps = {
      breadcrumb_title,
      title: seo_title,
      desc: seo_description,
      openGraphTitle: open_graph_title,
      openGraphDesc: open_graph_description,
      openGraphImgUrl: open_graph_image,
      type: articleSeoQuery ? 'article' : null,
      locationUrl: props.location.href,
    }
  }

  return (
    <>
      <SEO {...seoProps} />
      <PreviewStoreProvider>
        <LocaleProvider locale={props.pageContext.locale}>
          <ThemeProvider theme={theme}>
            <GlobalProvider location={props.location}>
              <StyleProvider location={props.location}>
                <Global styles={globalStyle} />
                <ClientOnly>
                  <Navigation
                    locale={props.pageContext.locale}
                    location={props.location}
                  />
                  <Layout {...props}>{element}</Layout>
                </ClientOnly>
              </StyleProvider>
            </GlobalProvider>
          </ThemeProvider>
        </LocaleProvider>
      </PreviewStoreProvider>
    </>
  )
}

export default wrapPageElement
