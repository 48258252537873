import styled from '@emotion/styled'
import { theme } from 'styles'
import Text from 'components/Utilities/Text'
import Title from 'components/Utilities/Title'

export const FlyoutContentStyled = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  max-height: 900px;

  @media (min-width: ${theme.breakpoints.m}) {
    top: 152px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 auto;
    transform: translateX(-80px);

    > * {
      width: 50%;
      margin-right: 0;
    }
  }

  .MenuColumn__SocialMedia {
    @media (min-width: ${theme.breakpoints.m}) {
      margin-right: 0px;
      margin-top: 0px;
    }
  }
`

export const MenuColumn = styled.div`
  max-width: 250px;
  min-width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media (min-width: ${theme.breakpoints.m}) {
    margin-top: 0px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
`

export const MenuItemHeading = styled(Text)`
  color: ${theme.colors.dirtywhite};
  text-transform: uppercase;
  font-size: ${theme.fonts.xxxs_small};
  font-weight: bold;
  margin-bottom: 18px;
  letter-spacing: ${theme.letterSpacing.primary};

  @media (min-width: ${theme.breakpoints.m}) {
    font-size: ${theme.fonts.xxxs_medium};
    margin-bottom: 27px;
  }
`

export const MenuItemBody = styled(Title)`
  color: ${theme.colors.lightblue};
  margin-bottom: 15px;
  font-weight: 100;

  @media (min-width: ${theme.breakpoints.m}) {
    margin-bottom: 22px;
  }
`
