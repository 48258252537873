import React from 'react'
import LocaleContext from 'context/LocaleProvider'
import { LanguageSwitcherContainer } from './styles.css'

const LocaleComponent = ({ className, clickHandler = () => {} }) => {
  const localeContext = React.useContext(LocaleContext)
  const i18n = localeContext.i18n[localeContext.locale]

  return (
    <LanguageSwitcherContainer
      className={className ? className : ''}
      onClick={() => {
        localeContext.setSelectedLocale(
          i18n.locale === 'fr-ca' ? 'en-gb' : 'fr-ca'
        )
        localeContext.setLanguageChanged(true)
        clickHandler()
      }}
    >
      <div className="Header__LanguageSwitcher-button">
        <p style={{ whiteSpace: 'nowrap' }}>
          {i18n.locale === 'fr-ca' ? 'English' : 'Français'}
        </p>
      </div>
    </LanguageSwitcherContainer>
  )
}

export default LocaleComponent
