import React from 'react'

export const Pinterest = ({ className, href }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="31"
        viewBox="0 0 32 31"
        className={className ? className : ''}
      >
        <a href={href} target="_blank" rel="noopener noreferrer">
          <g fill="none" fillOpacity=".39" fillRule="evenodd">
            <g fill="#E2EDED">
              <g>
                <path
                  d="M125.75 0C117.051 0 110 6.894 110 15.4c0 6.524 4.151 12.097 10.01 14.34-.138-1.218-.262-3.087.055-4.418.286-1.2 1.847-7.654 1.847-7.654s-.472-.922-.472-2.286c0-2.142 1.27-3.74 2.85-3.74 1.344 0 1.994.986 1.994 2.169 0 1.321-.86 3.296-1.305 5.127-.37 1.533.786 2.784 2.332 2.784 2.8 0 4.952-2.887 4.952-7.053 0-3.687-2.71-6.265-6.58-6.265-4.48 0-7.111 3.287-7.111 6.683 0 1.324.52 2.743 1.171 3.514.13.153.148.286.11.442-.12.487-.385 1.532-.437 1.747-.07.281-.229.342-.527.206-1.968-.896-3.197-3.708-3.197-5.967 0-4.857 3.61-9.318 10.406-9.318 5.464 0 9.71 3.806 9.71 8.894 0 5.307-3.422 9.579-8.173 9.579-1.596 0-3.096-.812-3.61-1.77 0 0-.79 2.941-.981 3.662-.355 1.337-1.315 3.014-1.958 4.037 1.475.446 3.04.687 4.664.687 8.699 0 15.75-6.895 15.75-15.4 0-8.506-7.051-15.4-15.75-15.4"
                  transform="translate(-156 -666) translate(46 666)"
                />
              </g>
            </g>
          </g>
        </a>
      </svg>
    </>
  )
}
