import React from 'react'
import PropTypes from 'prop-types'
import LocalizedLink from 'components/LocalizedLink'
import LocaleContext from 'context/LocaleProvider'

const LinkFormatter = ({
  pageType,
  pageHandle,
  children,
  className,
  onClickHandler,
}) => {
  const localeContext = React.useContext(LocaleContext)
  const i18n = localeContext.i18n[localeContext.locale]
  let path = `${pageHandle}`

  if (!pageHandle) {
    path = '/'
  } else if (pageType === 'job') {
    path =
      i18n.locale === 'fr-ca' ? `/jobs/${pageHandle}` : `/jobs/${pageHandle}`
  } else if (pageHandle === 'index' || pageHandle === 'home') {
    path = '/'
  } else if (pageType === 'project') {
    path =
      i18n.locale === 'fr-ca'
        ? `/projets/${pageHandle}`
        : `/projects/${pageHandle}`
  } else if (pageType === 'article') {
    path =
      i18n.locale === 'fr-ca' ? `/blogue/${pageHandle}` : `/blog/${pageHandle}`
  } else if (pageType === 'page') {
    path = `/${pageHandle}`
  }

  if (path.includes('//')) {
    path = path.replace('//', '/')
  } else if (!path.includes('/')) {
    path = `/${path}`
  }

  return (
    <LocalizedLink className={className} to={path} onClick={onClickHandler}>
      {children}
    </LocalizedLink>
  )
}

LinkFormatter.propTypes = {
  children: PropTypes.any,
  pageType: PropTypes.string,
  pageHandle: PropTypes.string,
  className: PropTypes.string,
  onMouseEnterHandler: PropTypes.func,
  onMouseLeaveHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
}

export default LinkFormatter
