import React from 'react'

export const Instagram = ({ className, href }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        className={className ? className : ''}
      >
        <a href={href} target="_blank" rel="noopener noreferrer">
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <path
                    fill="#E2EDED"
                    fillOpacity=".39"
                    d="M15.75 18.2c1.74 0 3.15-1.41 3.15-3.15s-1.41-3.15-3.15-3.15-3.15 1.41-3.15 3.15 1.41 3.15 3.15 3.15"
                    transform="translate(-101 -666) translate(46 666) translate(55)"
                  />
                  <path
                    fill="#5A7970"
                    d="M19.31 11.9h1.98c.226 0 .41-.184.41-.41V9.51c0-.226-.184-.41-.41-.41h-1.98c-.226 0-.41.184-.41.41v1.98c0 .226.184.41.41.41"
                    transform="translate(-101 -666) translate(46 666) translate(55)"
                  />
                  <path
                    fill="#E2EDED"
                    fillOpacity=".39"
                    d="M23.1 21.273c0 1.009-.82 1.827-1.83 1.827H9.53c-1.01 0-1.83-.818-1.83-1.827V9.526c0-1.009.82-1.826 1.83-1.826h11.74c1.01 0 1.83.817 1.83 1.826v11.747zM15.4 0C6.894 0 0 6.894 0 15.4c0 8.505 6.894 15.4 15.4 15.4s15.4-6.895 15.4-15.4C30.8 6.894 23.906 0 15.4 0z"
                    transform="translate(-101 -666) translate(46 666) translate(55)"
                  />
                  <path
                    fill="#E2EDED"
                    fillOpacity=".39"
                    d="M20.428 15.214c0 2.53-2.098 4.59-4.678 4.59s-4.678-2.06-4.678-4.59c0-.42.058-.827.167-1.214H9.8v6.577c0 .234.192.423.43.423h11.04c.238 0 .43-.19.43-.423V14h-1.439c.109.387.167.794.167 1.214"
                    transform="translate(-101 -666) translate(46 666) translate(55)"
                  />
                </g>
              </g>
            </g>
          </g>
        </a>
      </svg>
    </>
  )
}
