import React from 'react'
import PropTypes from 'prop-types'
import { LogoContainer } from './styles.css'

const Logo = ({ className, openMenu, clickHandler = () => {} }) => {
  return (
    <LogoContainer onClick={clickHandler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="224"
        height="133"
        viewBox="0 0 224 133"
        className={className ? className : ''}
      >
        <path
          fill={!openMenu ? '#043022' : '#fff'}
          d="M38.92 32.867c17.764 0 31.461 16.397 31.461 35.963 0 19.566-13.697 35.963-31.461 35.963-9.086 0-15.731-4.685-18.443-8.405v35.688H0V34.383h20.477v6.89c2.712-3.721 9.357-8.406 18.443-8.406zm70.828.608c14.898 0 27.087 7.64 28.035 22.1h-20.586c-.813-5.047-4.333-6.275-7.855-6.275-4.198 0-6.907 1.637-6.907 4.366 0 3.547 3.792 4.502 6.907 5.32l12.325 3.275c13.678 3.682 19.095 10.64 19.095 21.417 0 12.687-12.053 21.009-29.117 21.009-18.419 0-30.879-10.368-31.556-23.737h21.533c.271 5.457 4.876 8.185 10.7 8.185 5.282 0 7.584-1.91 7.584-4.366 0-3.001-2.438-4.365-7.72-5.73l-13.678-3.546C86.725 72.492 81.443 64.17 81.443 54.075c0-11.869 10.429-20.6 28.305-20.6zM171.554 0v41.27c2.712-3.715 9.357-8.393 18.443-8.393 17.765 0 31.46 16.37 31.46 35.905 0 19.534-13.695 35.905-31.46 35.905-9.086 0-15.731-4.678-18.443-8.392v6.878h-20.477V0h20.477zM34.309 51.744c-9.221 0-15.46 7.165-15.46 17.086s6.239 17.086 15.46 17.086c9.222 0 15.46-7.165 15.46-17.086s-6.238-17.086-15.46-17.086zm151.077-.02c-9.222 0-15.46 7.153-15.46 17.058 0 9.905 6.238 17.058 15.46 17.058 9.221 0 15.46-7.153 15.46-17.058 0-9.905-6.239-17.058-15.46-17.058z"
          transform="translate(-24) translate(24.94)"
        />
      </svg>
    </LogoContainer>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  LogoFill: PropTypes.string,
}

export default Logo
