import React, { createContext, useState, useEffect } from 'react'
import i18n from '../../config/i18n'
import { useSessionStorage } from 'react-use'
import { navigate } from 'gatsby'

const LocaleContext = createContext()

export const LocaleProvider = ({ children, locale }) => {
  const [pageDetails, setPageDetails] = useState({
    uid: '',
    type: '',
  })
  const [selectedLocale, setSelectedLocale] = useSessionStorage(null)
  const [languageChanged, setLanguageChanged] = useState(false)

  useEffect(() => {
    if (selectedLocale && languageChanged) {
      let path = '/'

      if (pageDetails?.type === 'project') {
        path =
          selectedLocale === 'en-gb'
            ? `/en/projects/${pageDetails?.uid}`
            : `/projets/${pageDetails?.uid}`
      } else if (pageDetails?.type === 'article') {
        path =
          selectedLocale === 'en-gb'
            ? `/en/blog/${pageDetails?.uid}`
            : `/blogue/${pageDetails?.uid}`
      } else if (pageDetails?.type === 'page') {
        path =
          selectedLocale === 'en-gb'
            ? `/en/${pageDetails?.uid}`
            : `/${pageDetails?.uid}`
      } else if (pageDetails?.type === 'home') {
        path = pageDetails?.uid
      } else {
        path =
          selectedLocale === 'en-gb'
            ? `/en/${pageDetails?.uid}`
            : `/${pageDetails?.uid}`
      }

      if (path.includes('//')) {
        path = path.replace('//', '/')
      }
      setLanguageChanged(false)
      navigate(path)
    }
  }, [languageChanged])

  useEffect(() => {
    if (pageDetails?.uid) {
    }
  }, [pageDetails])

  return (
    <LocaleContext.Provider
      value={{
        selectedLocale,
        setSelectedLocale,
        locale,
        i18n,
        pageDetails,
        setPageDetails,
        setLanguageChanged,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleContext
