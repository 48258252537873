import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import i18n from '../../../../config/i18n'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  title,
  desc,
  pathname,
  type,
  locale,
  openGraphTitle,
  openGraphDesc,
  openGraphImgUrl,
  locationUrl,
}) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteLanguage } = i18n[locale]

  const {
    siteMetadata: {
      siteUrl,
      defaultBanner,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: openGraphImgUrl || defaultBanner,
    url: locationUrl || siteUrl,
  }

  const website = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    headline: seo.title,
    description: seo.description,
    name: seo.title,
    author: 'Constructions psb Inc.',
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={openGraphImgUrl || seo.image} />
        <meta name={seo.title} content={seo.description} />
        <script type="application/ld+json">{JSON.stringify(website)}</script>
        <link
          rel="preload"
          as="font"
          href={'./static/fonts/Euclid/EuclidSquare-Light-WebS.woff2'}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={'./static/fonts/Euclid/EuclidSquare-Light-WebS.woff'}
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Facebook
        desc={seo.description}
        image={openGraphImgUrl || seo.image}
        title={openGraphTitle || seo.title}
        type={type ? type : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={openGraphTitle || seo.title}
        image={openGraphImgUrl || seo.image}
        desc={openGraphDesc || seo.description}
        username={twitter}
      />
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/6622300.js"
      ></script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-99009656-1"
      ></script>
      <script type="text/javascript">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
  
          gtag('config', 'UA-99009656-1');
        `}
      </script>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  locale: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  locale: 'en-gb',
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        headline
        defaultBanner: banner
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`
