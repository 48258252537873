import LocaleWrap from './wrapPageElement'
export const wrapPageElement = LocaleWrap

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
// export const onClientEntry = () => {
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//   }
// }

