import React from 'react'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { Loader } from 'components/Loader'

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    // <SwitchTransition mode={'out-in'}>
    //   <CSSTransition
    //     key={hasMounted}
    //     addEndListener={(node, done) => {
    //       node.addEventListener('transitionend', done, false)
    //     }}
    //     classNames="fade"
    //   >
    //     {!hasMounted ? <Loader /> : children}
    //   </CSSTransition>
    // </SwitchTransition>
    <>{!hasMounted ? <Loader /> : children}</>
  )
}

ClientOnly.propTypes = {
  children: PropTypes.any,
}

export default ClientOnly
