import React from 'react'

export const Dropdown = ({className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      className={className ? className : ''}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#043022">
          <g>
            <path
              d="M255 47L263 36 247 36z"
              transform="translate(-266 -480) translate(19 444)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
