import React from 'react'
import { BackgroundContainer } from './styles.css'

const AnimatedBackground = ({ children, openMenu,animationLoaded, className }) => {
  return (
    <BackgroundContainer
      openMenu={openMenu}
      className={className}
      animationLoaded={animationLoaded}
    >
      {children}
    </BackgroundContainer>
  )
}

export default AnimatedBackground
