import React from 'react'

export const BackArrow = ({ className, style, isDesktop }) => {
  return (
    <svg
      width="18"
      height="34"
      viewBox="0 0 18 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={ className ? className : ''}
    >
      <line
        y1="-0.75"
        x2="22.5836"
        y2="-0.75"
        transform="matrix(0.702768 -0.711419 0.702768 0.711419 1.129 17.8143)"
        stroke={style === 'style 2' && isDesktop ? '#e7f2f2' : '#043022'}
        strokeWidth="1.5"
      />
      <line
        y1="-0.75"
        x2="22.5836"
        y2="-0.75"
        transform="matrix(0.702768 0.711419 -0.702768 0.711419 1 17.6815)"
        stroke={style === 'style 2' && isDesktop ? '#e7f2f2' : '#043022'}
        strokeWidth="1.5"
      />
    </svg>
  )
}
