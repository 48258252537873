import styled from '@emotion/styled'
import { theme } from 'styles'
import { keyframes, css } from '@emotion/core'

const reveal1 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  `} 0.7s ease-in-out forwards;
`

const reveal2 = () => css`
  ${keyframes`
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  `} 0.7s ease-in-out forwards;
`

export const FlyoutNavigationContainer = styled.div`
  position: fixed;
  top: 77px;
  width: 100%;
  height: 100%;
  overflow: ${props =>
    props.openMenu && props.animationLoaded ? 'auto' : 'none'};
  z-index: ${props => (props.openMenu && !props.animationLoaded ? 3 : 1000)};
  pointer-events: ${props =>
    props.openMenu && props.animationLoaded ? 'auto' : 'none'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props =>
      !props.openMenu && !props.animationLoaded
        ? 'transparent'
        : props.theme.colors.primarygreen};
    transform-origin: bottom;
    animation: ${props =>
      props.openMenu && !props.animationLoaded
        ? reveal1
        : !props.openMenu && props.animationLoaded
        ? reveal2
        : ''};
  }

  @media (min-width: ${theme.breakpoints.m}) {
    height: auto;
    top: 152px;
    overflow: hidden;
  }
`

export const FlyoutMainContentStyled = styled.div`
  max-width: 2000px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 45px 0px 73px 46px;
  pointer-events: ${props =>
    props.openMenu && props.animationLoaded ? 'auto' : 'none'};
  opacity: ${props =>
    props.openMenu && props.animationLoaded
      ? 1
      : !props.openMenu && props.animationLoaded
      ? 0
      : 0};
  transform: ${props =>
    props.openMenu && props.animationLoaded
      ? 'translateY(0)'
      : 'translateY(20px)'};
  transition: ${theme.transition.primary};
  transition-property: opacity, transform;
  overflow: scroll;

  @media (min-width: ${theme.breakpoints.m}) {
    padding: 133px 100px;
    overflow: hidden;
    height: calc(100vh - 152px);
    top: 152px;
  }

  @media (min-width: ${theme.breakpoints.l}) {
    padding: 133px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`
export const PSBIconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 27px;
  margin-top: 62px;

  @media (max-width: ${theme.breakpoints.m}) {
    margin-bottom: 75px;

    svg {
      width: 52px;
      height: 52px;
    }
  }

  @media (min-width: ${theme.breakpoints.m}) {
    padding-right: 0;
    margin-top: 0;
    position: absolute;
    right: 97px;
    bottom: 97px;
    overflow: hidden;
  }
`
