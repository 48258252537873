import React, { createContext, useState, useEffect } from 'react'

const StyleContext = createContext()

export const StyleProvider = ({ children, location }) => {
  const [styleActivated, setStyleActivated] = useState('style 2')
  const [currentSectionVisible, setCurrentSectionVisible] = useState(null)

  useEffect(() => {
    setCurrentSectionVisible(null)

    if (location.pathname === '/') {
      setStyleActivated('style 2')
    }
    // else if (
    //   location.pathname === '/expertise-page-temporary' ||
    //   location.pathname === '/home-page-temporary'
    // )
    //   setStyleActivated('style 3')
    else {
      setStyleActivated('style 3')
    }
  }, [location.pathname])

  return (
    <StyleContext.Provider
      value={{
        setStyleActivated,
        styleActivated,
        currentSectionVisible,
        setCurrentSectionVisible,
      }}
    >
      {children}
    </StyleContext.Provider>
  )
}

export default StyleContext
