import React from 'react'

export const Zip = () => {
  return (
    <svg
      className="ZipLogo"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2976.76 1678.38"
      style={{ enableBackground: "new 0 0 2976.76 1678.38" }}
      xmlSpace="preserve"
    >
      <path
        fill="#23295E"
        d="M1470.56,1073.28c-13,0.48-25.58-4.66-34.52-14.12c-8.75-9.55-13.42-22.14-13-35.08
	c-0.12-13.04,3.1-25.88,9.36-37.32c5.5-10.23,12.21-19.75,20-28.36l193.24-218.48H1477.2c-16.32,0-28-5.6-34.4-16.72
	c-6.23-11.17-9.34-23.81-9-36.6c-0.45-12.32,3.1-24.46,10.12-34.6c6.96-9.59,18.28-15.04,30.12-14.48h297.6
	c12.58-0.58,24.85,4.02,33.96,12.72c8.44,8.44,12.72,20.44,12.72,35.64c0.36,11.84-2.11,23.59-7.2,34.28
	c-4.88,9.32-11.01,17.93-18.2,25.6l-201.24,225.08h190.68c11.47-0.29,22.39,4.91,29.4,14c7.47,10.16,11.24,22.56,10.68,35.16
	c0.54,13.12-2.6,26.14-9.08,37.56c-6.52,10.44-18.08,15.76-34.32,15.76L1470.56,1073.28L1470.56,1073.28z"
      />
      <path
        fill="#23295E"
        d="M1938.04,1079.04c-52.96,0-64-27.2-64-50v-332.8c0-17.4,5.24-31.48,15.64-41.84
	c10.4-10.36,26.96-15.64,49.28-15.64c18.8,0,33.68,4.28,44.28,12.76c10.6,8.48,16.48,22.16,16.48,39.76v336
	c0,18.28-5.76,31.72-17.12,40S1956.64,1079.04,1938.04,1079.04L1938.04,1079.04z M1938.04,603.04c-20.64,0-37.56-6.04-50.36-17.96
	c-12.8-11.92-19.48-28.36-19.48-48.56c-0.49-17.91,6.55-35.2,19.4-47.68c12.68-12.44,29.68-18.8,50.44-18.8
	c17.12,0.04,33.76,5.65,47.4,16c14.88,10.96,22.44,28,22.44,50.64c0,20.2-6.56,36.56-19.48,48.6
	c-12.92,12.04-29.84,17.88-50.36,17.88V603.04L1938.04,603.04z"
      />
      <path
        fill="#23295E"
        d="M2137.8,1206.57c-49.52,0-60-27.64-60-50.84V684.96c-0.43-14.75,5.24-29.01,15.68-39.44
	c10.36-10.36,26.96-15.64,49.28-15.64c13.88-0.14,27.6,3.04,40,9.28c13.16,6.76,20,20,20,39c2.67-3.07,5.53-6.21,8.6-9.44
	c8.41-8.53,17.81-16.03,28-22.32c24.77-15.46,53.48-23.46,82.68-23.04c27.98-0.54,55.71,5.46,80.96,17.52
	c23.11,11.41,43.23,28.07,58.76,48.64c16.18,22.01,27.99,46.91,34.8,73.36c7.79,29.89,11.61,60.67,11.36,91.56
	c0.29,31.46-4.11,62.79-13.04,92.96c-7.89,26.8-20.85,51.84-38.16,73.76c-16.68,20.66-37.82,37.27-61.84,48.6
	c-26.13,11.98-54.62,17.96-83.36,17.48c-19.25,0.55-38.45-2.15-56.8-8c-13.18-4.47-25.7-10.67-37.24-18.44
	c-6.25-4.25-12.02-9.17-17.2-14.68v107.2c0,18.64-5.08,32.4-15.12,40.88C2175.12,1202.69,2159.68,1206.57,2137.8,1206.57
	L2137.8,1206.57z M2290.04,730c-13.63-0.33-27.12,2.85-39.16,9.24c-11.33,6.43-21.05,15.36-28.4,26.12
	c-8.26,12.17-14.29,25.72-17.8,40c-4.14,16.6-6.17,33.65-6.04,50.76c0,38.96,8,70.2,24,92.88s37.72,33.28,67.56,33.28
	c29.84,0,51.56-11.36,66.64-34.76c15.08-23.4,23.12-54.56,23.12-91.4c0.05-17.06-2.05-34.06-6.24-50.6
	c-3.51-14.22-9.41-27.75-17.44-40c-7.14-10.77-16.74-19.7-28-26.04C2316.56,733.09,2303.39,729.82,2290.04,730L2290.04,730z"
      />
      <path
        fill="#F15C3F"
        d="M1199.96,746.64l-164.88,148.32H626.72c-38.09-0.05-74.88,13.83-103.44,39.04l205.96-185.32l3.16-2.8
	l258.04-232.36c41.61-37.43,100.02-49.81,153.24-32.49c53.22,17.32,93.15,61.71,104.76,116.46c11.61,54.75-6.87,111.53-48.48,148.95
	V746.64L1199.96,746.64z"
      />
      <path
        fill="#F8991F"
        d="M626.72,786.88c86.53,0,156.68-70.15,156.68-156.68c0-86.53-70.15-156.68-156.68-156.68
	S470.04,543.67,470.04,630.2C470.04,716.73,540.19,786.88,626.72,786.88L626.72,786.88z"
      />
      <path
        fill="#23295E"
        d="M626.72,894.96c-38.09-0.05-74.88,13.83-103.44,39.04l-1.32,1.2c-64.33,57.86-69.58,156.91-11.72,221.24
	c57.86,64.33,156.91,69.58,221.24,11.72L1035.12,895L626.72,894.96L626.72,894.96z"
      />
      <path
        fill="#00AEB8"
        d="M1035.12,894.96l-303.68,273.16c-28.76,25.86-66.09,40.11-104.76,40l468.52,0
	c89.09-1.42,154.18-71.49,154.18-156.66s-68.63-155.29-154.18-156.66L1035.12,894.96L1035.12,894.96z"
      />
      <g>
        <path
          fill="none"
          d="M313.36,786.88c86.53,0,156.68-70.15,156.68-156.68s-70.15-156.68-156.68-156.68S156.68,543.67,156.68,630.2
		S226.83,786.88,313.36,786.88L313.36,786.88z"
        />
        <path
          fill="none"
          d="M0,786.88L0,786.88c86.53,0,156.68-70.15,156.68-156.68S86.53,473.52,0,473.52V786.88z"
        />
      </g>
      <g>
        <path
          fill="none"
          d="M2663.4,473.52c-86.53,0-156.68,70.15-156.68,156.68s70.15,156.68,156.68,156.68s156.68-70.15,156.68-156.68
		S2749.93,473.52,2663.4,473.52L2663.4,473.52z"
        />
        <path
          fill="none"
          d="M2976.76,473.52L2976.76,473.52c-86.53,0-156.68,70.15-156.68,156.68s70.15,156.68,156.68,156.68V473.52z"
        />
      </g>
      <g>
        <path
          fill="none"
          d="M1780.2,313.36c0,86.53,70.15,156.68,156.68,156.68s156.68-70.15,156.68-156.68
		c0-86.53-70.15-156.68-156.68-156.68S1780.2,226.83,1780.2,313.36L1780.2,313.36z"
        />
        <path
          fill="none"
          d="M1780.2,0L1780.2,0c0,86.53,70.15,156.68,156.68,156.68S2093.56,86.53,2093.56,0H1780.2z"
        />
      </g>
      <g>
        <path
          fill="none"
          d="M2093.56,1365.02c0-86.53-70.15-156.68-156.68-156.68s-156.68,70.15-156.68,156.68
		c0,86.53,70.15,156.68,156.68,156.68S2093.56,1451.55,2093.56,1365.02L2093.56,1365.02z"
        />
        <path
          fill="none"
          d="M2093.56,1678.38L2093.56,1678.38c0-86.53-70.15-156.68-156.68-156.68s-156.68,70.15-156.68,156.68H2093.56z"
        />
      </g>
    </svg>
  )
}
